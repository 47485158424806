<template>
  <ess-card :loading="$wait.is('bodyCompositionLoading')"
            title="Body Composition"
            :no-action-padding="!edit">
    <v-expand-transition>
      <v-list class="transparent py-0"
              v-if="!edit">
        <v-list-item @click="edit = true">
          <v-list-item-action>
            <v-icon large
                    color="pink">
              mdi-scale-bathroom
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <v-row class="fill-width">
                <v-col cols="8">
                  <div class="ess-font-text-h5 grey--text text--lighten-2">
                    Body Weight
                  </div>
                </v-col>
                <v-col>
                  <div v-if="bodyCompositionData.weight"
                       class="ess-font-text-h4">
                    {{ bodyCompositionData.weight.current }} / {{ bodyCompositionData.weight.goal }}
                  </div>
                </v-col>
              </v-row>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item @click="edit = true">
          <v-list-item-action>
            <v-icon large
                    color="blue">
              mdi-math-compass
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <v-row class="fill-width">
                <v-col cols="8">
                  <div class="ess-font-text-h5 grey--text text--lighten-2">
                    Skinfold
                  </div>
                </v-col>
                <v-col>
                  <div v-if="bodyCompositionData.skinFold"
                       class="ess-font-text-h4">
                    {{ bodyCompositionData.skinFold.current }} / {{ bodyCompositionData.skinFold.goal }}
                  </div>
                </v-col>
              </v-row>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item @click="edit = true">
          <v-list-item-action>
            <v-icon large
                    color="orange">
              mdi-ruler
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <v-row class="fill-width">
                <v-col cols="8">
                  <div class="ess-font-text-h5 grey--text text--lighten-2">
                    Height
                  </div>
                </v-col>
                <v-col>
                  <div class="ess-font-text-h4">
                    {{ bodyCompositionData.height }}
                  </div>
                </v-col>
              </v-row>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-expand-transition>

    <v-expand-transition>
      <v-card-text v-if="edit"
                   class="d-flex transition-fast-in-fast-out v-card--reveal">
        <v-row>
          <v-col cols="12"
                 class="py-0">
            <ess-text-field v-model="bodyCompositionData.weight.current"
                            label="Weight"
                            placeholder="80"
                            mask="###"
                            type="number"
                            suffix="kg" />
          </v-col>

          <v-col cols="12"
                 class="py-0">
            <ess-text-field v-model="bodyCompositionData.skinFold.current"
                            label="Skin Fold"
                            placeholder="80"
                            mask="###"
                            type="number" />
          </v-col>

          <v-col cols="12"
                 class="py-0">
            <ess-text-field v-model="bodyCompositionData.height"
                            label="Height"
                            placeholder="185"
                            mask="###"
                            type="number"
                            suffix="cm" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-expand-transition>

    <template slot="actions"
              v-if="edit">
      <v-btn text
             @click="cancelUpdate">
        Cancel
      </v-btn>
      <v-spacer />
      <v-btn :loading="$wait.is('bodyCompositionUpdateLoading')"
             color="success"
             @click="updateAthleteBodyComposition">
        Update
      </v-btn>
    </template>
  </ess-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';
import _cloneDeep from 'lodash/cloneDeep';

import BodyComposition from '@/models/BodyComposition';

export default {
  components: {},

  props: {
    autoLoad: {
      type: Boolean,
      default: true,
    },
    userId: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      bodyCompositionData: new BodyComposition(),
      edit: false,
    };
  },

  computed: {
    ...mapGetters({
      bodyComposition: 'bodyCompositions/bodyComposition',
    }),
  },

  watch: {
    bodyComposition(value) {
      this.bodyCompositionData = _cloneDeep(value);
    },
  },

  async created() {
    await this.getBodyComposition(this.userId ? this.userId : this.authUser.email);
  },

  methods: {
    ...mapActions('bodyCompositions', ['getBodyComposition', 'updateBodyComposition']),

    ...mapWaitingActions('bodyCompositions', {
      getBodyComposition: 'bodyCompositionLoading',
      updateBodyComposition: 'bodyCompositionUpdateLoading',
    }),

    async updateAthleteBodyComposition() {
      await this.updateBodyComposition({
        bodyComposition: this.bodyCompositionData,
        athleteId: this.authUser.email,
      });

      this.edit = false;
    },

    cancelUpdate() {
      this.bodyCompositionData = new BodyComposition(this.bodyComposition);
      this.edit = false;
    },
  },
};
</script>
