<template>
  <ess-page-content title="Dashboard">
    <v-row>
      <v-col cols="12"
             md="6">
        <ess-body-composition />
      </v-col>
    </v-row>
  </ess-page-content>
</template>

<script>
import EssBodyComposition from '@/components/body/EssBodyComposition.vue';

export default {
  components: {
    EssBodyComposition,
  },

  props: {
    msg: {
      type: String,
      default: '',
    },
  },

  data() {
    return {};
  },

  computed: {},

  watch: {},

  beforeCreate() {},

  created() {},

  beforeMount() {},

  mounted() {},

  beforeUpdate() {},

  updated() {},

  beforeDestroy() {},

  destroy() {},

  methods: {},
};
</script>
